.App {
    overflow: hidden;
    color: aliceblue;
    height: 100vh;
    background-color: var(--bg);
}

.dark {
    --bg: #262A2E;
    --bg-d: #383A3F;
    --hover: #fff3;
    --active: #fffa;
    --color: #F0F8FF;
}

.light {
    --bg: #EAECEE;
    --bg-d: #EFF0F1;
    --color: #313133;
    --active: #097CFF;
    --hover: #367BFC94;

}

.billboard {
    height: 60vh;
    width: 45vw;
    min-width: 750px;
    max-width: 1000px;
    min-height: 520px;
    max-height: 650px;

    border: 3px solid;
    border-radius: 20px;
    padding: 2.5% 0;
    box-sizing: border-box;

    background-color: var(--bg-d);
    color: var(--color);

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 100;

    animation: rgborder linear 10s alternate-reverse infinite;
}

.slim {
    height: 80vh;
    width: 30vw;
    min-width: 500px;
    max-width: 600px;
    min-height: 675px;
    max-height: 1200px;

    border: 3px var(--color) solid;
    animation: none;

    padding: 20px 0 0 0;
    z-index: 3000;
}

.color-palette-container {
    overflow-y: auto;
    overflow-x: hidden;
}

.clickable {
    cursor: pointer;
    transition: scale .15s ease-out;
}

.clickable:hover {
    background-color: var(--hover);
    scale: 1.1;
}

.clickable:active {
    color: var(--bg) !important;
    background-color: var(--active);
    scale: .95;
}

.clickable.noscale {
    scale: 1 !important;
}

#timer {
    user-select: none;
    fill: whitesmoke;
}

.button {
    width: fit-content;

    border: 3px var(--bg) solid;
    border-radius: 10px;

    cursor: pointer;
    user-select: none;

    background-color: var(--color);
    color: var(--bg);
}

.button:hover {
    background-color: #3B97FF !important;
}

.button:active {
    background-color: #0066FF !important;
}

@keyframes rgborder {

    0%,
    100% {
        border-color: var(--color1);
    }

    33% {
        border-color: var(--color2);
    }

    66% {
        border-color: var(--color3);
    }
}

@keyframes rgbstroke {

    0%,
    100% {
        stroke: var(--color1);
    }

    33% {
        stroke: var(--color2);
    }

    66% {
        stroke: var(--color3);
    }
}


.circular-progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress-ring {
    z-index: 1000;
}

.progress-ring-circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.progress-ring-circle.background {
    stroke: #777;
}

.progress-ring-circle.progress {
    animation: rgbstroke linear 10s alternate-reverse infinite;
}



.timeInput {
    position: absolute;

    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    --size: 1.2rem;
    padding: 2% 2%;
    box-sizing: content-box;

    height: var(--size);
    width: 90px;

    font-size: var(--size);
    line-height: var(--size);


    border: white solid 1px;

    background-color: #333;
    color: aliceblue !important;
}

.timeInput span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-align: right;
    direction: rtl;
}


.timeInput span:focus {
    outline: none;
}


#seconds [contenteditable]:empty::before {
    content: "00";
    color: gray;

}

#minutes [contenteditable]:empty::before {
    content: "0";
    color: gray;
}

.startpause{
    transition: opacity .2s;
}

.progress-ring:hover .startpause{
    opacity: 1;
}

.pulse {
    border-radius: 50%;
    animation: pulsing 1.15s linear infinite;
}

.pulse:hover{
    animation: none;
}




@keyframes pulsing {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    }

    90%{
        box-shadow: 0 0 0 50px rgba(200, 200, 255, 0.1);
    }

    100% {
        box-shadow: 0 0 0 55px rgba(150, 200, 255, 0);
    }
}

